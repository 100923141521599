import React, { useEffect, useRef, useState } from 'react';
import SimplePeer, { Instance } from 'simple-peer';
import { v4 as uuidv4 } from 'uuid';

const Src = () => {
    const [isSharing, setIsSharing] = useState(false);
    const videoRef = useRef(null);
    const [stream, setStream] = useState<MediaStream | null>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let uuidFromUrl = urlParams.get('uuid');

        if (!uuidFromUrl) {
            const newUuid = uuidv4();
            window.history.replaceState(null, '', `?session=${newUuid}`);
        }
    }, []);

    const startScreenShare = async () => {
        try {
            // Request screen sharing from the user
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: false, // Set to true if you want to capture audio as well
            });

            // Set the video element to display the screen share
            if (videoRef.current) {
                // @ts-ignore
                videoRef.current.srcObject = screenStream;
            }

            setStream(screenStream);
            setIsSharing(true);

            // Handle ending the screen share
            screenStream.getTracks().forEach((track) => {
                track.onended = () => {
                    stopScreenShare();
                };
            });
        } catch (error) {
            console.error('Error starting screen share:', error);
        }
    };

    const stopScreenShare = () => {
        if (stream) {
            // Stop all tracks in the stream
            stream.getTracks().forEach((track) => track.stop());
        }

        setIsSharing(false);
    };

    return <></>
}

export default Src;