import React, {useEffect} from 'react';
import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';
import './App.css';
import Src from "./Src2";
import {v4 as uuidv4} from "uuid";
import Home from "./Home";


const theme = createTheme({
    palette: {
        primary: {
            main: '#3A3D41', // Slate Gray - lighter than pure black for better contrast
        },
        secondary: {
            main: '#007ACC', // Visual Studio Code Blue
        },
        background: {
            default: '#252526', // Dark Gray
        },
        text: {
            primary: '#D4D4D4', // Light Gray
            secondary: '#9CDCFE', // Light Blue
        },
        action: {
            active: '#569CD6', // Bright Blue for interactive elements
        },
    },
    typography: {
        fontFamily: 'Fira Code, monospace', // Monospaced font for coding feel
    },
});

function App() {
    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     let uuidFromUrl = urlParams.get('id');
    //
    //     if (!uuidFromUrl) {
    //         const newId = uuidv4();
    //         window.history.replaceState(null, '', `?id=${newId}`);
    //         // setIsInitiator(true);
    //     } else {
    //         const signal = urlParams.get('signal');
    //         if (signal) {
    //             // connectToPeer(signal);
    //         } else {
    //             // setIsInitiator(true);
    //         }
    //     }
    // }, []);

  return (
    <div>
        <Home theme={theme} />
      {/*<Src />*/}
    </div>
  );
}

export default App;
