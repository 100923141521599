import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Src from './Src2';
import Logo from './Logo.png';

const Home = ({ theme }: any) => {
    const currentTheme = useTheme();
    const [inSession, setInSession] = useState<boolean>(false);

    const handleButtonClick = () => {
        setInSession(true);
        // alert('Coming soon: you will be able to start a standup with your team using crowd/src\'s tools');
    };

    return (
        <Container
            maxWidth={false}
            style={{
                display: 'flex',
                flexDirection: 'column', // Align children vertically
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%', // Make the container full width
                backgroundColor: theme.palette.background.default,
                padding: 0, // Remove default padding if needed
            }}
        >
            {/* Logo at the top */}
            <img
                src={Logo}
                alt="Logo"
                style={{
                    maxWidth: '200px', // Adjust size as needed
                    marginBottom: '20px', // Add space between logo and content
                }}
            />

            {!inSession && (
                <Box
                    textAlign="center"
                    style={{
                        color: theme.palette.text.primary,
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        style={{ color: theme.palette.primary.main }}
                    >
                        This is crowd/src
                    </Typography>
                    <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>
                        We believe that better collaborative tools will enhance productivity, streamline agile processes, and accelerate development velocity.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleButtonClick}
                        style={{ marginTop: '20px' }}
                    >
                        new session
                    </Button>
                </Box>
            )}
            {inSession && <Src />}
        </Container>
    );
};

export default Home;
